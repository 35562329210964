<template>
  <div class="packageRegistration px-sm-3">
    <h1 class="h2">パッケージ登録</h1>

    <div class="my-5">
      <!-- <Btn flat color="primary" icon="backspace">戻る</Btn> -->
      <!-- <Btn class="ml-3" color="info" icon="details">編集</Btn> -->
      <!-- <Btn class="ml-3" color="warning" icon="toy-brick-plus-outline">スケジュール参照asdf</Btn> -->
      <v-btn flat color="primary">戻る</v-btn>
      <v-btn class="ml-3" color="info">編集</v-btn>
      <v-btn class="ml-3" color="warning">見積参照</v-btn>
    </div>

    <v-form>
      <template>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selecthospital"
              :items="hospitals"
              label="病院"
              outlined
              item-text="name"
              item-value="code"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              v-model.number="quoteNumber"
              label="見積番号"
              type="number"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model.number="billAmount"
              label="請求金額"
              type="number"
              outlined
              suffix="円"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="workContent"
              label="作業内容"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col class="">
            <v-text-field
              v-model.number="daycount"
              label="日数"
              type="number"
              suffix="日"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
          <v-col class="">
            <label>開始時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="startTime"
              placeholder="開始時間"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
          <v-col class="">
            <label>終了時間</label>
            <vue-timepicker
              class="ml-7"
              v-model="endTime"
              placeholder="終了時間"
              manual-input
              auto-scroll
              input-width="95%"
            >
              <template v-slot:clearButton>
                <img src="@/assets/Clear.png" />
              </template>
            </vue-timepicker>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col class="">
            <v-text-field
              v-model.number="ninsuu"
              label="人数"
              type="number"
              suffix="人"
              outlined
              prepend-icon="mdi-human-queue"
            ></v-text-field>
          </v-col>
          <v-col class="">
            <v-autocomplete
              v-model="selectcycle"
              :items="cycles"
              label="作業サイクル"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-sync-circle"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col class="">
            <v-text-field
              v-model.number="monthcount"
              label="月回数"
              type="number"
              suffix="回"
              outlined
              prepend-icon="mdi-numeric"
            ></v-text-field>
          </v-col>
        </v-row>

        <DatePicker
          v-model="startdate"
          label="開始年月"
          prepend-icon="mdi-calendar"
          outlined
          clearable
          type="month"
        />
        <v-autocomplete
          v-model="selectFilterCompany"
          :items="filterCompany"
          label="協力会社"
          prepend-icon="mdi-account-cog"
          outlined
          item-text="name"
          item-value="code"
          return-object
          clearable
        ></v-autocomplete>
        <v-text-field
          v-model="weekday"
          label="曜日備考"
          outlined
          prepend-icon="mdi-calendar-week"
          maxlength="45"
        ></v-text-field>

        <v-row style="height: 60px;">
          <v-col cols="2">
            <v-text-field
              value="指定曜日"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="monday" label="月"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="tuesday" label="火"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="wednesday" label="水"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="thursday" label="木"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="friday" label="金"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="saturday"
              label="土"
              color="secondary"
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="sunday" label="日" color="error"> </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="nholiday" label="祝" color="success">
            </v-checkbox>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>

        <v-row style="height: 90px;">
          <v-col cols="2">
            <v-text-field
              value="指定週"
              prepend-icon="mdi-calendar-week"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week1" label="1週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week2" label="2週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week3" label="3週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week4" label="4週目"></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-checkbox v-model="week5" label="5週目"></v-checkbox>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>

        <v-radio-group
          v-model="selectNationalHoliday"
          row
          label="祝日設定"
          prepend-icon="mdi-toggle-swtich"
        >
          <v-radio
            label="指定曜日祝日対象外"
            :value="SelectsNationalHoliday.No"
          ></v-radio>
          <v-radio
            label="指定曜日祝日対象"
            :value="SelectsNationalHoliday.YesWd"
          ></v-radio>
        </v-radio-group>

        <v-row>
          <p
            prepend-icon="mdi-information"
            style="color:red"
            class="text-h7 spacing-playground pa-1"
          >
            <strong>{{ nhoff }}</strong>
          </p>
        </v-row>

        <v-row v-for="(item, i) in meisai" :key="i">
          <v-text-field
            :value="item.display"
            class="mr-5"
            :label="'明細内容 ' + (i + 1)"
            outlined
            dense
            readonly
            prepend-icon="mdi-close"
            required
          />
          <v-btn color="success" v-if="edit"> 編集</v-btn>
        </v-row>

        <v-row>
          <v-btn rounded color="primary" small>
            <v-icon>mdi-plus</v-icon> 明細を追加</v-btn
          >
        </v-row>

        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success">
          完了
        </v-btn>
      </div>
      <!-- *************** form END *************** -->
    </v-form>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import DatePicker from "@/components/DatePicker.vue";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

// const DATE = new Date();
// const DATENOW =
//   DATE.getFullYear() +
//   "-" +
//   ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
//   "-" +
//   ("00" + Number(DATE.getDate())).slice(-2);

const SelectsDelete = {
  Delete: 0,
  Default: 1
};

const SelectsNationalHoliday = {
  No: 0,
  YesWd: 1,
  YesHd: 2
};

export default {
  mixins: [Common, Forms, Api, ShowDialogs],
  // mixins: [Api],
  components: {
    "vue-timepicker": VueTimepicker,
    DatePicker
  },
  data() {
    return {
      edit: true,
      selecthospital: "",
      hospitals: [],
      quoteNumber: "49073",
      billAmount: "126000",
      daycount: "1",
      workContent: "8",
      startTime: "08:30",
      endTime: "15:30",
      ninsuu: "2",
      selectcycle: "", //随時
      cycles: [],
      monthcount: "1",
      startdate: "2025/02",
      selectFilterCompany: "",
      filterCompany: [],
      weekday: "",
      monday: false, //***
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      nholiday: false,
      week1: false,
      week2: false,
      week3: false,
      week4: false,
      week5: false,
      selectNationalHoliday: SelectsNationalHoliday.No,
      SelectsNationalHoliday,
      nhoff: "",
      meisai: [{ packageMeisaiNo: "1", display: "" }],
      selectDelete: SelectsDelete.Default,
      SelectsDelete
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.CYCLE);
      this.cycles = filter.filter(e => e.code !== NotSetCode.CYCLE);

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
      this.filterCompany = filter.filter(e => e.code !== NotSetCode.HLP);
    },
    getTextIetems() {
      console.log("getTextItems", this.codeMasterItems);
      let textlst = [];
      textlst = this.codeMasterItems.filter(e => e.refcode === CodeGroup.TEXT);
      const text = textlst.filter(e => e.code === "0");
      console.log("text1", text);
      this.nhoff = text[0].option2;
    }
  },
  async created() {
    await this.getCodeMasterItems();
    this.getPullDownItems();

    this.getTextIetems();
  }
};
</script>

<style scoped>
label {
  font-size: 1em;
}

.packageRegistration .row + .row {
  margin-top: 0;
}

/* .dialog-padding {
  padding-top: 0px;
} */
</style>
